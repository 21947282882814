.stockist-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    border: 1px solid $grey-d;
    width: 48%;
    margin: 10px 0;
    padding: 19px;

    @include breakpoint($desktop) {
      width: 30.5%;
      margin: 16px 0;
      padding: 25px;
    }
  }

  h3 {
    @include fontSize(14px);
    @include ptBold;
    margin: 0 0 16px;
    letter-spacing: 0.5px;
  }

  p {
    @include fontSize(14px);
    margin: 0;
    line-height: 1.3;
  }
}


.recently-viewed {
  border-top: 1px solid #C1C1C1;
  text-align: center;

  &__heading {
    @include fontSize(32px);
    font-weight: 600;

  }
}

#recently_viewed {
  .custom-slider {
    border-top: 1px solid #C1C1C1;
    margin: 20px 0;
    padding: 20px 0;

    @include breakpoint($desktop) {
      margin: 62px 0;
      padding: 62px 0;
    }

    .content-heading .title {
      @include fontSize(28px);
      font-weight: 600;
      display: block;
      width: 100%;
      text-align: center;
      text-transform: uppercase;

      @include breakpoint($desktop) {
        @include fontSize(32px);
      }
    }
  }
}

.owl-carousel-products-,
.owl-carousel.products {
  &.owl-theme .owl-dots {
    bottom: 10px;
  }

  .product-item .price-box {
    margin: 10px 0 0;
  }
}

.owl-carousel.products .owl-stage-outer {
  padding: 0;
}
