.theme-pearl.store-view-default .main-banner {
  position: relative;
  margin-bottom: 28px;
  
  @include breakpoint($desktop) {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    margin-bottom: 64px;
  }

  &__content {
    margin: 15px 0 0;

    @include breakpoint($desktop) {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      max-width: 1120px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 15px;
      margin: 0;
    }
  }

  &__heading {
    @include fontSize(20px);
    @include ptBold;
    margin: 0 0 10px;

    @include breakpoint($desktop) {
      @include fontSize(64px);
      margin: 0 0 20px;
    }
  }

  &__text {
    @include ptMedium;
    @include fontSize(16px);
    font-weight: $bold;
    max-width: 420px;
  }

  &__image {
    display: block;

    img {
      width: 100%;
    }
  }
}
