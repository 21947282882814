.weltpixel_newsletter {
  .title {
    padding: 7px 0 18px;
    font-weight: 600;
  }

  .newsletter-image-container {
    img {
      width: 100%;
    }
  }
}

.newsletter-fields {
  display: flex;
  position: relative;
  flex-direction: column;

  @include breakpoint($desktop) {
    flex-direction: row;
  }

  &::before {
    content: "\e866";
    font-family: font-icons;
    font-size: 16px;
    position: absolute;
    left: 18px;
    top: 14px;
  }

  input {
    height: 50px !important;
    background: $white !important;
    border: 1px solid $grey-d !important;
    width: 100% !important;
    text-align: left;
    padding-left: 43px;

    @include breakpoint($desktop) {
      width: 77% !important;
    }

    &::placeholder {
      color: $black;
      opacity: 1;
    }
  }

  button {
    height: 50px !important;
    background: #292929 !important;
    margin-left: 2px;
    text-transform: uppercase;
    margin-top: 24px;

    @include breakpoint($desktop) {
      margin-top: 0;
    }

    span {
      font-size: 14px !important;
      color: $white !important;
    }
  }
}

.newsletter-text-small {
  @include fontSize(14px);
  color: #4E4F50;
  margin: 8px 0 0;
  float: left;
  width: 100%;

  a {
    text-decoration: underline;
  }
}

.newsletter-image-container {
  display: none;

  @include breakpoint($desktop) {
    display: block;
  }
}

.weltpixel_newsletter #weltpixel_newsletter {
  #popup_newsletter_email-error {
    @include fontSize(11px);
    top: 50px;
    font-weight: 500;
  }

  .terms-condition-container {
    margin-top: 22px;

    #terms_conditions_consent {
      top: 0;
    }
  }

  #terms_conditions_consent-error {
    @include fontSize(11px);
    font-size: 11px;
    font-weight: 500;
    text-align: center;
  }
}

.newsletter-checkout-wrapper {
  &.block.newsletter {
    .field.newsletter {
      max-width: 100%;
      width: 100%;
      margin: 0 0 5px;
      float: none;
    }
  }

  &.newsletter .form.subscribe {
    width: auto;
    margin: 0 auto;
    display: block;

    .actions {
      float: none;

      button {
        width: 100%;
      }
    }
  }
}
