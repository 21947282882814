// Discount Block
#block-discount {
  .title {
    &::after {
      display: none;
    }
  }

  .content {
    display: block !important; // overwrite inline
  }

  .actions-toolbar {
    padding: 0 0 0 2px;
  }
}


#payment .discount-code {
  .payment-option-content {
    display: block !important;
  }

  .action-toggle {
    color: $black;

    &::after {
      display: none;
    }
  }

  .action-apply {
    border: 1px solid $black;
    margin: -1px 0 0 4px;
    box-shadow: none;
  }
}

.checkout-index-index {
  .usp-bar {
    display: none;
  }
}
.disabled-checkout-message{
    text-align: center;
    font-size: 20px;
    padding: 20px;
    background: #000;
    color: #fff;
}

// Payment Step
.checkout-payment-method .checkout-billing-address .billing-address-details {
  line-height: 1.4;
  padding: 0;
}

.braintree-credit-card-selected {
  width: 31px;
}

#braintree_cc_number {
  padding-left: 50px;
}

#braintree_expirationDate {
  width: 120px;
}

.payment-method-braintree {
  .cvv .field-tooltip {
    top: 1px;
  }

  .hosted-control  {
    padding: 8px 10px;
    height: 33px;
  }
}

//Store Switcher
.checkout-index-index {
    #switcher-store{
        z-index: 10;

        #switcher-store-trigger{
            color: #0a0a0a;
        }
    }
}

// Basket Page
.totals,
.opc-block-summary {
  .totals-tax, .grand.totals.excl {
    display: none;
  }
}

table.table-checkout-shipping-method {
  width: 100%;
  tbody {
    display: table-row-group;
  }
}
