.page-footer .footer-newsletter {
  background: $green;
  text-align: center;
  padding: 100px 35px;

  &__inner {
    max-width: 460px;
    margin: 0 auto;
  }

  &__heading {
    @include fontSize(30px);
    @include ptBold;
    color: $white;
    margin: 0;
  }

  &__text {
    color: $white !important;
    margin: 30px 0 24px;
  }

  .form-group {
    display: flex;
    position: relative;
    flex-wrap: wrap;

    @include breakpoint($desktop) {
      flex-wrap: nowrap;
    }

    &::before {
      content: "\e866";
      font-family: font-icons;
      font-size: 16px;
      position: absolute;
      left: 18px;
      top: 14px;
    }

    &__input {
      height: 50px;
      text-transform: uppercase;
      padding: 0 10px 0 50px;

      &::placeholder {
        color: $black;
        opacity: 1;
        letter-spacing: 0.1px;
      }
    }

    &__button {
      @include fontSize(16px);
      @include ptBook;
      background: #292929;
      border: 0 !important;
      text-transform: uppercase;
      margin: 5px 0 0 2px;
      letter-spacing: 0.6px;
      font-weight: 300;
      width: 100%;
      padding: 13px;


      @include breakpoint($desktop) {
        width: auto;
        margin-top: 0;
        padding: 10px 15px;
      }
    }
  }

  #newsletter-bottom-error {
    position: absolute;
    bottom: -21px;
    left: 0;
    color: $white;
  }
}

// 3 Column info blocks
.footer-info-blocks {
  display: flex;
  justify-content: space-between;
  margin: 50px auto 10px;
  max-width: 1160px;
  width: 100%;
  flex-direction: column;
  padding: 0 45px;

  @include breakpoint($desktop) {
    flex-direction: row;
    padding: 0 15px;
    margin: 95px auto 105px;
  }

  h3 {
    @include ptBold;
    margin: 0;

    @include breakpoint(max-width $desktop) {
      @include fontSize(24px);
    }
  }

  p {
    margin: 10px 0 0;
    text-transform: uppercase;
  }

  &__block {
    background: $white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    padding: 20px 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 10px 0;

    @include breakpoint($desktop) {
      width: 31.5%;
    }
  }
}

.theme-pearl .page-wrapper .page-footer .footer-v1 {
  max-width: 1160px;
}
