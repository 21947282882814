.footer-bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #D8D8D8;
  align-items: center;
  padding: 25px 0 10px;
  margin: 25px 0 0;
  flex-direction: column;

  @include breakpoint($desktop) {
    flex-direction: row;
    width: 120%;
    margin-left: -10%;
    padding: 10px 10.5%;
  }

  &__block {
    margin: 5px 0;

    @include breakpoint($desktop) {
      margin: 0;
    }
  }
}

.page-wrapper .page-footer {
  .footer.content {
    .footer-v1-content {
      .col-sm-4 {
        width: 100%;

        @include breakpoint($desktop) {
          width: 28%;
        }
      }

      .col-sm-2 {
        width: 100%;

        @include breakpoint($desktop) {
          width: 20%;
        }
      }

      .col-sm-1 {
        width: 100%;
        
        @include breakpoint($desktop) {
          width: 12%;
        }
      }

      .footer-title {
        @include fontSize(14px);
        letter-spacing: 0.5px;
      }
    }

    .links {
      li {
        margin: 0 0 1px;
        padding: 0;
      }

      a {
        @include fontSize(13px);
        text-transform: uppercase;
        letter-spacing: 0.5px;
        padding: 0;
      }
    }

    .ekomi-widget-wrapper {
      padding: 0 20px;
      background-color: $white;

      @media (min-width: 767px) {
        padding: 0 50px;
      }

       .ekomi-widget-container {
        border-top: 1px solid #d8d8d8;
        padding: 20px;

        @media (min-width: 767px) {
          padding: 40px 40px 30px 40px;
        }
       }
    }
  }

  .ekomi {
    margin: 30px 0 0;
    
    @include breakpoint($desktop) {
      max-width: 140px;
      padding: 0 0 0 10px;
      margin: 21px 0 0 auto;
    }
  }

  .row {
    margin-left: 0;
    padding: 0 5px;
  }

  .social-icons {
    margin-left: 15px;
  }

  .si-instagram:hover, .si-colored.si-instagram {
    background-color: #bc2a8d !important;
  }
}
