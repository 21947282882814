.product-info-main {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 85px;

  @include breakpoint($desktop) {
    padding-top: 65px
  }

  klarna-placement {
    position: absolute;
    left: 0;
    top: -10px;
  }

  .page-title-wrapper {
    order: 2;

    h1 {
      @include ptBold;
      @include fontSize(20px);
      line-height: 1.2;

      @include breakpoint($desktop) {
        @include fontSize(32px)
      }

      span {
        text-transform: uppercase;
      }
    }
  }

  #widget-container {
    order: 3;
    margin: 0 0 15px;
    min-height: 16px;

    .stars-widget {
      display: none;
      float: none !important;
    }
  }

  .product-add-form {
    padding-top: 0;
  }

  .product-info-stock-sku {
    @include fontSize(12px);
  }

  .stock.available {
    font-weight: 500;
  }

  > div {
    order: 9;
  }

  .product-brand-logos {
    order: 1;

    .value {
      font-size: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 0 15px;
      width: 170px;
      height: 45px;
      display: none;

      @include breakpoint($desktop) {
        display: block;
      }
    }

    .double-two {
      background-image: url("../images/doubletwo-logo.png");
    }

    .bar-harbour {
      background-image: url("../images/bar-harbour.png");
    }
    .lambretta {
        background-image: url("../images/lambretta.png");
    }
  }

  .wp-product-label-extra {
    order: 4;
    margin: -4px 0 15px;

    .wp-product-label {
      width: 100%;
      margin: 0;

      span {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.theme-pearl.catalog-product-view {
  .swatch-option.text {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 11px;
    margin-right: 8px;
    font-weight: 500;

    &::before {
      height: 35px;
      width: 35px;
      top: 0;
      left: 0;
    }
  }

  .swatch-attribute {
     &.shirt_fit,
     &.fit_options {
       .swatch-option.text {
         border-radius: 5px;
         width: auto;
         height: auto;
         line-height: 14px;
         border: 1px solid #ddd !important;
         text-transform: uppercase;
         background: none;
         padding: 12px;
         font-family:'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
         background: #f0f0f0;

         &:hover{
           border: 1px solid #999 !important;
         }
         &::before {
           content: none;
         }
       }
     }
  }

  .swatch-opt {
    min-height: 70px;
  }

  .swatch-option.color {
    width: 35px;
    height: 35px;
    min-width: 35px;
    transition: .3s ease opacity;
    border: 1px solid #ccc !important;

    &.selected {
      opacity: .5;
    }

    &::before {
      display: none;
    }

    &::after {
      top: 0;
      left: 0;
      width: 35px;
      height: 35px;
      line-height: 35px;
    }
  }

  .swatch-attribute .swatch-attribute-label {
    text-transform: uppercase;
  }

  .box-tocart {
    .field.qty input {
      border-left: 1px solid $grey-d !important;
      border-right: 1px solid $grey-d !important;
    }

    .tocart {
      text-transform: uppercase;
    }
  }

  #recently_viewed .custom-slider {
    border-top: 0;
    border-bottom: 1px solid #c1c1c1;
    padding-top: 0;
  }

  .column.main .block .block-title.title {
    @include fontSize(28px);
    font-weight: 600;
    padding: 0 5px;

    @include breakpoint($desktop) {
      @include fontSize(32px);
    }
  }
}

.action.tocompare {
  display: none !important;
}

.theme-pearl.store-view-default .action.towishlist {
  font-weight: 500;
  font-size: 12px !important;

  &::before {
    top: -2px;
  }
}

.fotorama__arr {
  &.fotorama__arr--prev {
    .fotorama__arr__arr {
      background: url("../images/icons/chevron-left.svg") no-repeat center;
      background-size: 18px;
    }
  }

  &.fotorama__arr--next {
    .fotorama__arr__arr {
      background: url("../images/icons/chevron-right.svg") no-repeat center;
      background-size: 18px;
    }
  }
}

.block-margin.block.row {
  .block-actions,
  .field.choice  {
    display: none;
  }

  .product-item-info  {
    box-shadow: none !important;
    border: 0 !important;
  }
}

#open-size-guide-modal {
  margin: 10px 0 0;
}

.product-item .price-box .price {
  font-weight: $bold;
  font-size: 16px !important;
}

.product.data.items .item.title a {
  @include fontSize(16px);
  text-transform: uppercase;
}

.theme-pearl.store-view-default select {
  letter-spacing: .4px;
}

.product-info-price .price-box .price-container .price {
  @include fontSize(32px);
  @include ptBook;
  font-weight: $bold;
}

.product-item-name {
  hyphens: none;
  word-wrap: unset;
}

.gallery-placeholder {
  max-width: 800px;
  margin: 0 auto;
}

.fotorama__nav-wrap {
  margin: 15px 0 5px;
}

.size-guide-container {
  text-align: center;
}

.product-colour-select {
  letter-spacing: normal !important;
}

.swatch-attribute.matrixkey,
#ekomi-product-widget-identifier  {
  display: none;
}

#widget-container .container-fluid {
  margin: 0;
}
