.cms-container-small {
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
}

.cms-row {
  display: flex;
  margin: 32px 0;
  flex-direction: column;

  @include breakpoint($desktop) {
    flex-direction: row;
    margin: 64px 0;
  }

  &.alternative {
    .cms-col:first-child {
      @include breakpoint(max-width $desktop) {
        order: 2;
      }
    }
  }
}

.cms-col {
  @include breakpoint($desktop) {
    flex-basis: 50%;
  }
  
  @include breakpoint($desktop) {
    &:first-child {
      padding: 0 25px 0 0;
    }

    &:last-child {
      padding: 0 0 0 25px;
    }
  }

  img {
    width: 100%;
  }
}

.cms-image {
  margin-top: 20px;
}

.theme-pearl.cms-our-shirt-making-heritage {
  h1 {
    @include fontSize(34px);

    @include breakpoint($desktop) {
      @include fontSize(61px);
    }
  }

  p {
    @include fontSize(16px);
  }
}
