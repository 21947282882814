.page-products {
  .columns .sidebar-additional,
  .toolbar-products .modes {
    display: none;
  }

  .sidebar {
    @include breakpoint(max-width $desktop) {
      margin: 0 !important;
    }
  }
}

#maincontent .category-banner {
  width: 100%;
  margin: 0 auto 55px;
  position: relative;

  .category-banner__image + .category-banner__content {
    margin: 15px 0 0;

    @include breakpoint($desktop) {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      max-width: 1120px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 15px;
      margin: 0;
    }

    .category-description {
      @include breakpoint($desktop) {
        max-width: 440px !important;
      }
    }
  }

  .page-title {
    @include fontSize(20px);
    margin: 10px 0;
    line-height: 1.1;

    @include breakpoint($desktop) {
      @include fontSize(32px);
      margin: 20px 0;
    }
  }

  .category-description {
    @include ptMedium;
    @include fontSize(16px);
    font-weight: $bold;

    p {
      @include fontSize(16px);
    }
  }

  &__image {
    display: block;

    img {
      width: 100%;
    }
  }
}

.category-show-more {
  display: none;
}

.products {
  .product-item {
    .ekomi-widget-container {
      display: flex;
      justify-content: center;
      margin: 10px 0 0;
    }
  }

  .product-item-inner {
    display: none !important;
  }
}

.page-products .products {
  .product-image-wrapper {
    overflow: visible;
  }
}

.theme-pearl.catalog-product-view #maincontent .product-info-main {
  .product-info-stock-sku {
    margin: 10px 0;
  }

  .product-info-price .price-box .old-price {
    width: auto;
    display: block !important;
    margin: 0 0 8px;
    float: none;

    @include breakpoint($desktop) {
      margin: 0 0 2px;
    }

    .price-label {
      display: block !important;
      margin: 0 4px 0 0;
      clip: auto;
      height: auto;
      overflow: visible;
      position: relative;
      width: auto;

      &::after {
        display: none;
      }
    }

    .price-container {
      display: flex;
      float: none;
    }

    .price-label,
    .price-container,
    .price-wrapper,
    .price {
      @include fontSize(12px);
      line-height: 1;
    }

    + .normal-price .price-final_price .price {
      @include fontSize(32px);
      @include ptBook;
      font-weight: $bold;
      color: #ff0000;
      width: 50%;
    }
  }
}

.products-grid .product-item .product-item-name {
  @include ptMedium;
  line-height: 0.8;
  margin: 5px 0 15px;

  a {
    font-size: 1.7rem !important;
    line-height: 1.4;
  }
}

.products-grid .product-item .product-item-info {
  border: 0 !important;
  box-shadow: none !important;

  &:hover {
    border: 0 !important;
    box-shadow: none !important;
  }
}

.column.main .products-grid .product-item .product-item-info .product.photo.product-item-photo {
  margin: 0 0 20px;
}

.wp-product-label.wp-product-label-bottom-center {
  bottom: -25px;
  left: -7%;
  text-align: center;
  width: 114%;

  span {
    width: 100%;
  }
}

.sidebar #layered-filter-block #narrow-by-list {
  .swatch-option {
    width: 38px !important;
    height: 38px !important;
    min-width: 38px !important;
    line-height: 38px !important;
    background-size: cover !important;
  }
}

.stars-widget {
  display: none;
  .total_reviews {
    visibility: visible;
  }
}


.stars-widget.pull-left {
  .total_reviews {
    float: none !important;
    display: block;
    margin: 0 auto;


    @include breakpoint($xs-mobile) {
      display: flex;
      float: left;
    }
  }
}
