// Involves overwriting a lot of importants and specific selectors from Welt MegaMenu
.navigation {
  .megamenu.level-top-sectioned {
    .sectioned {
      width: 100%;
      left: 0 !important;
    }

    .submenu .submenu-child {
      padding: 15px 0 0;
      @include breakpoint($desktop) {
        display: flex;
      }

      .columns-group {
        padding: 0;
        flex: 1 1 0;

        li {
          @include breakpoint($desktop) {
            padding-left: 5px !important;
            padding-right: 5px !important;
          }
        }
      }
    }
  }

  .megamenu .level0.submenu .menu-block.right-block {
    width: 100% !important;
    position: relative;
  }

  .megamenu.level0 > .submenu {
    background: $grey-6 !important;
    text-align: left;

    .submenu {
      background-color: transparent !important;
    }
  }

  .megamenu.level0 .submenu a {
    &:hover {
      background: transparent !important;
    }

    img {
      display: block;
      margin: 0 auto;
    }

  }
}

// Desktop & Desktop-large Menu
.nav-sections {
  @include breakpoint($desktop) {
    width: 100%;
  }

  @include breakpoint($desktop-large) {
    position: relative !important;
    float: none !important;
    margin: 0 auto !important;
    width: calc(100% - 316px);
    text-align: center;
    left: 30px !important;
  }

  .section-item-content {
    padding: 0 !important;
  }

  .navigation > ul {
    position: relative !important;

    & > li:last-child {
      display: none;
    }

    .fullwidth {
        @include breakpoint($desktop) {
            position: fixed !important;
            .fullwidth-wrapper {
                background: #f6f6f6 !important;
            }
        }
    }
  }
}

:root .theme-pearl .page-wrapper .page-header.sticky-header:not(.page-header-v4) {
  .sections.nav-sections {
    left: 0 !important;

    @include breakpoint($desktop-large) {
      left: 30px !important;
    }
  }

  &.page-header-v3 .sections.nav-sections {
    margin-left: auto !important;
  }
}

.nav-sections,
body:not(.mobile-nav) .nav-sections .navigation,
body:not(.mobile-nav) .page-header.sticky-header .nav-sections-item-content,
body:not(.mobile-nav) .nav-sections .nav-sections-items,
body:not(.mobile-nav) .page-header.sticky-header .navigation {
  background: transparent !important;
}

.nav-sections-item-content {
  @include breakpoint($desktop) {
    display: inline-block !important;
  }
}

.navigation .megamenu.level-top-sectioned .sectioned .columns-group li > a {
  font-weight: $bold;
}

.shop-now {
  color: $white;
  background: $dark-grey;
  text-transform: uppercase;
  padding: 11px 15px;
  display: inline-block;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}
