.size-guide-container {
  @include breakpoint(max-width $desktop) {
    display: flex;
    flex-direction: column;
  }

  .tab-container {
    @include breakpoint(max-width $desktop) {
      padding: 0;
    }
  }
}

.size-table {
  text-transform: uppercase;

  th {
    font-weight: 500;
    background: $silver-light;
  }

  td {
    text-align: center;
  }
}

ul.tab-nav:not(.tab-nav-lg) li a {
  background: $silver-light;
}

.side-tabs ul.tab-nav {
  @include breakpoint(max-width $desktop) {
    width: 100%;
    padding: 0;

    li.ui-tabs-active a {
      right: 0;
    }
  }

  li a {
    line-height: 1.3;
    padding: 15px 10px;
    font-weight: $bold;
    text-transform: uppercase;
  }
}
