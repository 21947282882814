.usp-bar {
  background: $silver;
  text-align: center;
  transition: 0.3s ease opacity;
  min-height: 47px;

  div[id="eKomiWidget_default"],
  .ekomi-custom {
    width: 125px;
  }

  .owl-stage {
    display: flex;
    align-items: center;
  }

  &__slider {
    margin: 0 auto;
    max-width: 1400px;
    padding: 0 40px;

    &:not(.owl-loaded) {
      visibility: hidden;
    }

    @include breakpoint ($desktop) {
      padding: 0 15px;
    }
  }

  .owl-carousel {
    .owl-stage-outer {
      padding: 9px 0;
    }

    .owl-item {
      @include fontSize(14px);
      text-transform: uppercase;

      @include breakpoint ($desktop) {
        @include fontSize(17px);
      }

      & > div {
        @include ptMedium;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
      }

      i {
        font-size: 19px;
      }
    }
  }

  .owl-theme .owl-controls .owl-nav [class*="owl-"] {
    background: none;
    transform: scale(0.7);

    &:hover {
      background: none;

      &::before {
        background-color: $black;
      }
    }

    &.owl-prev {
      left: 0;

      @include breakpoint($desktop) {
        left: -5px;
      }
    }

    &.owl-next {
      right: 0;

      @include breakpoint($desktop) {
        right: -5px;
      }
    }
  }
}

.usp-hidden {
  opacity: 0;
}

#cookie-status,
#pre-div {
  display: none;
}
