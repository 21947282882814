:root .theme-pearl .page-wrapper {
  .page-header.sticky-header:not(.page-header-v4) .logo {
    @media only screen and (min-width: $desktop) and (max-width: $desktop-large)  {
      margin-top: -30px;
    }
  }

  .page-header .logo {
    max-width: 135px;
    margin: 0 auto;
    display: block;
    float: none;

    @include breakpoint($desktop) {
      float: left;
      max-width: 175px;
    }

    img {
      width: auto !important;
      height: auto !important;
    }
  }
}

div.page-wrapper .page-header .panel.wrapper {
  background: $dark-grey !important;
  display: block !important;
}

.page-wrapper .page-header {
  .navigation .level0 {
    margin: 0;

    &::marker {
      display: none;
    }

    &:hover > a {
      background: $grey-6;
    }
  }

  .navigation .level0 > .level-top {
    padding: 0 6px;
    font-weight: $bold;

    @include breakpoint($desktop-large) {
      @include fontSize(14px);
    }

    @include breakpoint($desktop-larger) {
      @include fontSize(16px);
    }
  }

  .action.showcart {
    .text {
      @include ptMedium;
      @include fontSize(12px);
      height: auto;
      width: auto;
      clip: auto;
      display: none;
      margin: 0 0 0 -5px;

      @include breakpoint($desktop) {
        display: block;
      }
    }
  }

  .open-modal-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1px;

    span {
      @include fontSize(12px);
      @include ptMedium;
      display: none;

      @include breakpoint($desktop) {
        display: block;
      }
    }
  }

  .block-search.minisearch-v2 {
    margin-top: -4px;
    transition: none;

    @include breakpoint(max-width $desktop) {
      position: absolute;
      left: 37px;
      top: 27px;
    }

    .open-modal-search {
      @include breakpoint(max-width $desktop) {
        margin: 0;
        font-size: 18px !important;
      }
    }
  }

  .header .header_right {
    margin-top: -4px;
    margin-right: 28px;

    @include breakpoint(max-width $desktop) {
      margin-top: 0;
      margin-right: 0;
      position: absolute;
      top: 24px;
      right: 15px;
    }
  }
}

.header.panel {
  display: flex;
  justify-content: flex-end;

  & > .header.links {
    margin-left: 0;

    & > li {
      @include breakpoint($desktop) {
        margin-left: 0;
      }

      i {
        position: relative;
        top: 1px;
        margin: 0 2px 0 0;
      }

      &.authorization-link::after {
        margin: 0 9px 0 4px;
      }

      &.authorization-link {
        &::before {
          content: "\e6c3";
          font-size: 13px;
          margin: 0 3px 0 0;
          font-family: "font-icons";
          color: $white;
        }
      }
    }
  }

  .links {
    .wishlist,
    .wishlist-link,
    .blog-link {
      display: none;
    }
  }
}

:root {
  .theme-pearl .page-wrapper .page-header-v3 .desktop-logo.desktop-logo-all {
    @include breakpoint(max-width $desktop) {
      margin: 9px auto 0;
      float: none;
      display: block;
    }
  }

  .page-header-v3 .page-header .header.content {
    padding-left: 15px !important;
    padding-top: 21px;
    padding-bottom: 21px;
    min-height: 79px;
  }
}

.wishlist-mobile {
  font-size: 16px;
  position: absolute;
  top: 3px;
  left: -22px;

  @include breakpoint($desktop) {
    display: none;
  }
}

body:not(.mobile-nav) .nav-sections .navigation {
  @include breakpoint($desktop) {
    padding-left: 0 !important;
  }

  ul li.level0 > a {
    letter-spacing: 0.5px;
  }
}

.page-wrapper .page-header {
  .minicart-wrapper .action.showcart.active::before,
  .minicart-wrapper .action.showcart::before {
    font-size: 18px !important;

    @include breakpoint($desktop) {
      font-size: 16px !important;
    }
  }

  .block-search.minisearch-v2 .open-modal-search {
    font-size: 16px !important;
  }
}

.page-header {
  .navigation .megamenu.level-top-sectioned .sectioned .columns-group {
    overflow: visible;
  }

  .header.links {
    li.wishlist-link {
      @include breakpoint($desktop) {
        margin: 0 30px;
      }
    }
  }
}

.nav-sections-items {
  .greet {
    display: none;
  }

  .customer-welcome {
    border: 0 !important;
    padding: 0 !important;
  }
}

.compare.wrapper {
  display: none;
}

.nav-sections .section-item-content {
  padding: 0;
}

// Store Switcher
.switcher-store {
  &__flag {
    width: 20px;
    height: 20px;
    background-position: center;
    font-size: 0;
    display: block;

    &.gb_en,
    &.admin {
      background-image: url("../images/icons/gb_en.svg");
    }

    &.eu_en {
      background-image: url("../images/icons/eu_en.svg");
    }
    &.eu_en {
      background-image: url("../images/icons/eu_en.svg");
    }

  }
}

#switcher-store-trigger {
  position: relative;
  padding: 0 20px 0 0;

  @include breakpoint($desktop) {
    float: left;
  }

  &::after {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.page-header #switcher-store {
  order: 0;
  float: none;
  margin: 1px 25px -5px auto;

  .switcher-options {
    z-index: 10000;
  }
  .switcher-dropdown {
    @include breakpoint($desktop) {
      box-shadow: 1px 0 10px -6px $black;
    }

    .switcher-option {
      background-color: $white;

      a {
        @include breakpoint($desktop) {
          margin: 6px auto;
        }
      }
    }
  }

  .options ul.dropdown {
    margin-top: 10px;
    min-width: 50px;

    li a {
      font-size: 0;
    }
  }
}

.header-multistore {
  display: none !important; // overwrite welt importants
}

// Mobile Store Switcher
.nav-sections .section-item-content {
  #switcher-store {
    display: block !important; // overwrite welt importants
    border: 0;

    @include breakpoint($desktop) {
      display: none !important; // overwrite welt importants
    }
  }
}

.block.block-minicart.ui-dialog-content.ui-widget-content {
  max-height: 560px;
}

.minicart-items-wrapper {
  max-height: 280px;

  & + .actions {
    border-top: 1px solid $grey-c;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 0;
    background: $white;
    z-index: 50;
  }
}

.block-minicart {
  .subtotal .label::after {
    display: none;
  }

  .block-content {
    & * {
      @include fontSize(12px);
    }

    & > .actions {
      margin-top: 10px;

      & .paypal-logo {
        margin-top: 10px;
      }

      & > .primary {
        margin: 0 10px 10px;

        .action.primary {
          @include fontSize(14px);
          padding: 8px 5px;
        }
      }
    }

    .subtotal {
      margin: 0 10px 10px 0;
    }
    .discount-total, .grand-total{
        text-align: right;
        margin: 0 10px 10px;
    }
  }

  .amount .price-wrapper:first-child .price {
    display: inline-block;
    margin: 4px 0 0;
  }
}

.minicart-wrapper .block-minicart {
  padding: 10px 25px 20px;
}

.minicart-items .product-item {
  padding: 13px 0;
}
