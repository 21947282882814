.homepage-image-blocks {
  margin: 32px;
  
  @include breakpoint($desktop) {
    margin: 80px 10px;
    display: flex;
    justify-content: space-between;
  }

  & > a {
    width: 100%;
    position: relative;
    margin: 0 0 24px;
    display: block;

    @include breakpoint($desktop) {
      width: 32.5%;
      margin: 0;
    }

    img {
      display: block;
    }

    span {
      color: $white;
      background: $dark-grey;
      text-transform: uppercase;
      padding: 16px 20px;
      display: inline-block;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }
  }
}

#maincontent {
  .content-v5 {
    .subtitle {
      margin: 10px 0 20px;

      @include breakpoint($desktop) {
        margin: 40px 0 30px;
      }

      h4 {
        @include fontSize(20px);
        @include ptBook;
        letter-spacing: 0.8px !important;
        font-weight: $bold;
        margin-top: 0;

        @include breakpoint($desktop) {
          @include fontSize(32px);
        }
      }
    }

    .price-label {
      @include fontSize(12px);
    }

    .product-item-name {
      margin: 6px 0 10px;

      a {
        font-weight: $bold;
        line-height: 1.5;
      }
    }
  }

  .green-title {
    @include ptBold;
    line-height: 1.35;
  }

  .white-text {
    color: $white;
  }

  p.white-text {
    @include fontSize(16px);
    @include ptMedium;
    font-weight: $bold;
    line-height: 1.4;
  }

  .price-label,
  .tocart span {
    @include fontSize(16px);
    text-transform: uppercase;
  }
}

.content-grey {
  background: #434445;

  &-light {
    background: #4E4F50;
  }
}

.content-orange {
  background: #C4AC66;

  &-light {
    background: #C9B373;
  }
}

// Owl Slider
.owl-theme .owl-controls .owl-nav [class*="owl-"].owl-prev {
  &::before,
  &::after {
    opacity: 0.75;
  }
}

.content-v5 .block.widget .products-grid {
  .product-items .product-item {
    @include breakpoint(max-width $mobile) {
      width: 45%;
      margin: 0 2.5%;
    }

    .product-item-info {
      border: 0 !important;
    }
  }
}

@include breakpoint(max-width $desktop) {
  .content-v5 {
    .box-content-2.col-md-8,
    .row .col-md-4,
    .row .col-md-8 {
      width: 100%;
    }
  }
}

// Product blocks
.price-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .price {
    font-weight: $bold;
  }

  .old-price {
    display: block !important;
    margin-right: 4px;

    + .normal-price .price-container.price-final_price .price-wrapper .price {
      color: #FF0000 !important; 
    }
  }
}

.theme-pearl .products-grid .product-item .product-item-details .price-box {
  .price-container .price-label {
    display: none !important;
  }
}

.cms-index-index {
  .owl-carousel .owl-item img {
    height: auto;
  }

  .custom-slider .owl-height {
    transition: none;
  }

  .custom-slider {
    > div:not(.owl-loaded) {
      .banner-item {
        display: none;

        &:nth-child(1) {
          display: block;
        }
      }
    }
  }
}
