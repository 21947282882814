$xs-mobile: 450px;
$mobile: 768px;
$desktop: 1040px;
$desktop-large: 1180px;
$desktop-larger: 1300px;

.cms-page-view #maincontent {
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
  padding-top: 30px;

  @include breakpoint($desktop) {
    padding-top: 50px;
  }
}

.theme-pearl.store-view-default .page-wrapper .breadcrumbs {
  background: #FAFAFA;
}

.theme-pearl.store-view-default .page-main {
  padding-top: 15px;
}

.theme-pearl.store-view-default .page-wrapper button,
.theme-pearl.store-view-default .page-wrapper button span,
.theme-pearl.store-view-default .cart-discount .block.discount .content .fieldset .primary button span,
.theme-pearl.store-view-default button.action.action-cancel span {
  @include fontSize(16px);
  @include ptBook;
  font-weight: $bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
 