$bold: 400;

// Convert px to rem
@mixin fontSize($pix) {
  $remVal: $pix / 10px;
  font-size: $remVal + rem;
}

@mixin ptLight {
  font-family: sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin ptBook {
  font-family: sans-serif;
  font-weight: $bold;
  font-style: normal;
}

@mixin ptMedium {
  font-family: sans-serif;
  font-weight: $bold;
  font-style: normal;
}

@mixin ptBold {
  font-family: sans-serif;
  font-weight: 700;
  font-style: normal;
}

body {
  @include ptBook;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include ptBold;
}

.block .block-content *,
.block .block-title strong,
.login .field.note,
.block .empty {
  @include fontSize(14px);
}

.login .field.note {
  letter-spacing: 1px;
}
